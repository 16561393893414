import * as React from "react";
import type { SVGProps } from "react";
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={104}
    height={39}
    fill="none"
    viewBox="0 0 104 39"
    {...props}
  >
    <g fill="currentcolor" clipPath="url(#0-a)">
      <path d="M29.358 30.087c1.23-.307 1.76-1.047 1.76-5.068v-8.787c0-3.581-.442-3.976-1.76-4.54v-.396l3.732-1.618.31.263V25.02c0 4.02.529 4.76 1.758 5.068v.483h-5.793v-.483zm.959-24.825c0-.934.73-1.662 1.67-1.662s1.67.734 1.67 1.662a1.65 1.65 0 0 1-1.67 1.662 1.65 1.65 0 0 1-1.67-1.662M43.367 10.556v14.463c0 4.02.529 4.76 1.758 5.068v.483h-5.97v-.483c1.23-.307 1.76-1.047 1.76-5.068V5.544c0-4.02-.53-4.76-1.76-5.067v-.483h11.07c2.92 0 5.283 2.352 5.283 5.256s-2.364 5.256-5.283 5.256h-6.852V9.94h4.709c2.604 0 4.71-2.095 4.71-4.685S50.685.571 48.081.571h-4.71v9.979zm0-.608h4.652l1.885.526c5.882.828 6.707 4.78 8.763 9.622 1.922 4.54 4.249 10.13 5.654 10.13.265 0 .486-.045.744-.089v.44c-.308.131-1.185.307-2.15.307-3.158 0-4.835-6.128-6.486-10.086-2.364-5.67-3.228-10.23-10.03-10.23h-3.026" />
      <path d="M61.043 36.792c.977.44 2.85.358 4.659-.596 1.809-.953 3.511-3.248 4.74-5.826l-6.38-15.31c-1.582-3.714-2.502-4.322-3.378-4.63v-.482h5.44v.483c-1.23.307-1.185.915.397 4.629l5.195 12.38 5.453-12.5c1.185-2.753 1.286-4.208-.252-4.516v-.483h4.697v.483c-1.098.308-2.194.916-3.777 4.63L71.42 29.691c-4.168 9.565-8.82 9.302-10.358 9.302l-.02-2.208zM84.25 23.276v-7.044c0-3.581-.442-3.976-1.76-4.54v-.396l3.732-1.618.31.263v12.325c0 5.325 2.105 7.426 5.793 7.426 3.29 0 5.882-2.139 7.634-4.19v-9.27c0-3.581-.441-3.976-1.752-4.54v-.396l3.732-1.618.309.263V25.02c0 4.02.529 4.76 1.752 5.068v.483h-2.938c-.788 0-1.097-.307-1.097-1.75v-2.534c-1.973 2.14-5.05 4.673-8.82 4.673-4.563 0-6.89-3.01-6.89-7.683zM4.21.564h4.71c2.604.007 4.71 2.108 4.71 4.692s-2.106 4.685-4.71 4.685H4.21v.565h6.854c2.918 0 5.282-2.352 5.282-5.256S13.99 0 11.07 0H4.21z" />
      <path d="M4.211 10.512h9.772c5.396 0 9.771 4.353 9.771 9.722s-4.375 9.722-9.771 9.722l-7.27-.076c-1.638 0-2.502-1.022-2.502-2.897V0H0v.483C1.23.79 1.759 1.53 1.759 5.551v19.475c0 4.02-.53 4.76-1.759 5.068v.482h16.113c5.725 0 10.365-4.616 10.365-10.31 0-5.696-4.64-10.312-10.365-10.312H4.211" />
    </g>
    <defs>
      <clipPath id="0-a">
        <path fill="#fff" d="M0 0h104v39H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLogo;
