import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { Animate } from './animate'
import { CroppedText } from './cropped-text'

const Outer = styled.div`

`

const Text = styled(CroppedText)`
  font-size: 1rem;
  line-height: 23.75;
  letter-spacing: 0.1em;
  text-align: center;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 1.4285714285714286;
    letter-spacing: 0.1em;
  }
`

type SectionHeadProps = {
  children?: ReactNode
}
export const SectionHead: FunctionComponent<SectionHeadProps> = ({children}) => {
  return <Outer>
    <Text>
      ({children})
    </Text>
  </Outer>
}