import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { CroppedText } from './cropped-text'
import { CheckoutPopup, Variant } from './checkout-popup'
import { useModal } from '../hooks/use-modal'
import classNames from 'classnames'

const Outer = styled.div``

const Inner = styled.div``

const Name = styled(CroppedText)`
  font-size: 2.25rem;
  line-height: 1.4;
  font-family: var(--serif-font);
  small {
    font-size: 1rem;
  }
`

const Description = styled(CroppedText)`
  font-size: 0.9375rem;
  line-height: 4.266666666666667;
  font-family: var(--serif-font);
  margin-top: 1.25rem;
  font-family: var(--sans-serif-ja-font);
`

const Text = styled(CroppedText)`
  font-size: 0.9375rem;
  line-height: 1.8666666666666667;
  margin-top: 2.5rem;
  font-family: var(--sans-serif-ja-font);
`

const Note = styled(CroppedText)`
  font-size: 0.6875rem;
  line-height: 1.6363636363636365;
  margin-top: 1.875rem;
  font-family: var(--sans-serif-ja-font);
`

const PriceContainer = styled.div`
  margin-top: 3.125rem;
`

const PriceRow = styled.div`
  background-color: #F4F2F0;
  border-radius: 0.25rem;
  padding: 1.375rem 1.875rem;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  &.disabled {
    color: #888;
    pointer-events: none;
  }
  & + & {
    margin-top: 1.875rem;
  }
`

const Label = styled.div`
  font-size: 1rem;
  margin-right: auto;
  font-family: var(--sans-serif-ja-font);
`

const Price = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  font-family: var(--sans-serif-ja-font);
`

const PriceLabel = styled.div`
  font-size: 0.75rem;
`

const BuyButton = styled.div`
  margin-top: 1.875rem;
  padding: 1.25rem 0;
  text-align: center;
  font-weight: 600;
  background-color: var(--blue);
  color: white;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.4s;
  font-family: var(--sans-serif-ja-font);
  &:hover {
    background-color: color-mix(in lch, var(--text-color) 50%, white);
  }
  &.disabled {
    background-color: #888;
    pointer-events: none;
  }
  &.pre-order {
    background-color: var(--orange);
  }
`

const SubscriptionNotice = styled.div`
  font-size: 0.6875rem;
  line-height: 1.3636363636363635;
  font-weight: 500;
  font-family: var(--sans-serif-ja-font);
  margin-top: 1.5rem;
  a {
    text-decoration: underline;
  }
`


type ProductInfoProps = {
  data: {
    name: string,
    description: string,
    text: string,
    note?: string,
    subscriptionNotice?: string
    variants: Variant[],
    soldOut?: boolean,
    preOrder?: boolean
  }
}
export const ProductInfo: FunctionComponent<ProductInfoProps> = ({data}) => {
  const [open, setOpen] = useState(false)
  const {open: openModal} = useModal()
  const [sending, setSending] = useState(false)
  const [selected, setSelected] = useState<number | string>()
  const addToCart = async (variantId: string | number) => {
    const data = {
      authenticity_token: document.querySelector<HTMLMetaElement>('[name="csrf-token"]')!.content,
      variant_id: variantId.toString(),
      quantity: '1'
    }

    const form = document.createElement('form')
    form.method = 'POST'
    form.action = `/shop/cart/add`
    for(const [key, value] of Object.entries(data)){
      const input = document.createElement('input')
      input.name = key
      input.value = value
      form.appendChild(input)
    }
    document.body.appendChild(form)
    form.submit()
    return
  }
  return <Outer>
    <Inner>
      <Name dangerouslySetInnerHTML={{__html: data.name}} />
      <Description dangerouslySetInnerHTML={{__html: data.description}} />
      <Text dangerouslySetInnerHTML={{__html: data.text}} />
      {
        data.note && <Note dangerouslySetInnerHTML={{__html: data.note}} />
      }
      <PriceContainer>
        {data.variants.map((variant, i) => {
          return <PriceRow key={i} className={classNames({disabled: data.soldOut})} onClick={() => {
            setSelected(variant.id)
            setOpen(true)
          }}>
            <Label dangerouslySetInnerHTML={{__html: variant.label}} />
            <Price>
              &yen;{variant.price.toLocaleString('en-US')}
            </Price>
            <PriceLabel dangerouslySetInnerHTML={{__html: `（税込）/${variant.netContent}`}} />
          </PriceRow>
        })}
      </PriceContainer>
      <BuyButton className={classNames({disabled: data.soldOut, 'pre-order': data.preOrder})} onClick={() => setOpen(true)}>
        { data.soldOut ? 'SOLD OUT' : data.preOrder ? '予約する' : 'BUY NOW' }
      </BuyButton>
      {
        data.subscriptionNotice && <SubscriptionNotice dangerouslySetInnerHTML={{__html: data.subscriptionNotice}} />
      }
    </Inner>
    <CheckoutPopup
      data={{
        ...data
      }}
      open={open}
      onClose={() => {
        setOpen(false)
        setSelected(undefined)
      }}
      defaultSelect={selected}
      onClick={addToCart}
    />
  </Outer>
}