import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionOuter } from '../components/section-outer'
import { SectionHead } from '../components/section-head'
import { AssetImage } from '../components/image'
import { pageMaxWidth } from '../styles/page-max-width'
import { CroppedText } from '../components/cropped-text'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSwiperClass } from '../hooks/useSwiperClass'
import { SvgLeftAngle, SvgRightAngle } from '../components/svg'
import { pxr } from '../styles/pxr'

const Outer = styled.div`
  margin-top: ${pxr(120)};
  @media (max-width: 40rem){
    margin-top: ${pxr(60)};
  }
`

const HeadContainer = styled.div`
  ${pageMaxWidth}
`

const Inner = styled.div`
  background-color: #F2F2F2;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-top: ${pxr(48)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const Item = styled.a`
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: clip;
  @media (max-width: 60rem){
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  & + & {

    border-left: 1px solid #000;
    @media (max-width: 40rem){
      border-left: 0 none;
      border-top: 1px solid #000;
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  overflow: clip;
  transition: opacity 0.4s;
  ${Item}:hover & {
    opacity: 0.5;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    transition: opacity 0.8s, transform 0.8s;
    margin-inline: auto;
    max-width: 30rem;
    @media (max-width: 40rem){
      width: 80%;
    }
  }
`

const Name = styled(CroppedText)`
  font-size: 1.125rem;
  line-height: 1.7777777777777777;
  text-align: center;
  margin-top: 3rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 2.2857142857142856;
    margin-top: 2rem;
  }
`

const Button = styled.div`
font-size: 0.9375rem;
letter-spacing: 0.1em;
border: 1px solid #000;
border-radius: 100rem;
padding: ${pxr(9)} ${pxr(22)};
width: fit-content;
margin-inline: auto;
margin-top: ${pxr(42)};
display: flex;
gap: 1rem;
align-items: center;
transition: background-color 0.4s, color 0.4s;

@media (max-width: 40rem){
  font-size: 0.8087499737739563rem;
  letter-spacing: 0.1em;
  padding: ${pxr(8)} ${pxr(18)};
  margin-top: ${pxr(33)};
}
&:hover {
  background-color: #181F6F;
  border-color: #181F6F;
  color: white;
}
svg {
  height: 1em;
  width: auto;
  display: block;
  margin-top: 0.25em;
}
`

const Price = styled.div`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.75;
  font-family: var(--sans-serif-ja-font);
  margin-top: 1rem;
`

const Controls = styled.div`
  margin-top: 2.25rem;
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
  margin-left: auto;
  margin-right: 5rem;
`

const ControlsButton = styled.div`
  padding: 1rem;
  margin: -1rem;
  cursor: pointer;
  svg {
    display: block;
    height: 0.75rem;
    width: auto;
  }
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.5;
  }
`


type ProductsSectionProps = {

}
export const ProductsSection: FunctionComponent<ProductsSectionProps> = () => {
  const {ref, slideNext, slidePrev, hasNext, hasPrev, slideCount} = useSwiperClass()
  return <Outer>
    <HeadContainer>
      <SectionHead>
        PRODUCTS
      </SectionHead>
    </HeadContainer>
    <Inner>
      <Item href="/shop/products/BI0001_BC_F_na">
        <ImageContainer>
          <AssetImage src="p1_0.png" />
        </ImageContainer>
        <Name>
          BiRyu ダーマチャージセラム
        </Name>
        <Button>
          View More
          <SvgRightAngle size={12} />
        </Button>
        <Price>
          【定期・30日毎に1個】¥4,320（税込価格 ¥4,752） <br />
          【単品】 ¥4,800（税込価格 ¥5,280）
        </Price>
      </Item>
      <Item href="/shop/products/	BI0002_EC_F_na">
        <ImageContainer>
          <AssetImage src="p2_0.png" />
        </ImageContainer>
        <Name>
          BiRyu ダーマチャージスポットクリーム
        </Name>
        <Button>
          View More
          <SvgRightAngle size={12} />
        </Button>
        <Price>
          【定期・30日毎に1個】¥3,256（税込価格 ¥3,582）<br />
          【単品】¥3,618（税込価格 ¥3,980）
        </Price>
      </Item>
    </Inner>
    {
      slideCount > 2 && <Controls>
        <ControlsButton onClick={slidePrev}>
          <SvgLeftAngle size={12} />
        </ControlsButton>
        <ControlsButton onClick={slideNext}>
          <SvgRightAngle size={12} />
        </ControlsButton>
      </Controls>
    }
  </Outer>
}