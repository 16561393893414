import React, { Fragment, FunctionComponent, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { isNullish } from '../utils/type-check'
import { SvgCross } from './svg'
import { useModal } from '../hooks/use-modal'
import { Popup } from './popup'

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  z-index: 100;
  transition: opacity 0.4s, transform 0.4s;
  &.hide {
    pointer-events: none;
  }
`

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #bababa44;
  transition: backdrop-filter 0.4s, background-color 0.4s;
  backdrop-filter: blur(0.5rem);
  ${Outer}.hide &{
    backdrop-filter: none;
    background-color: transparent;
  }
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #bababa44;
  transition: backdrop-filter 0.4s, background-color 0.4s;
  backdrop-filter: blur(0.5rem);
  pointer-events: none;
  &.hide {
    backdrop-filter: none;
    background-color: transparent;
  }
`

const Inner = styled.div`
  background-color: white;
  position: relative;
  padding: 3rem;
  transition: transform 0.4s;
  box-sizing: border-box;
  min-width: min(26rem, 100%);
  ${Outer}.hide & {
    transform: translateX(100%);
  }
`

const Brand = styled.div`
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: 0.06em;
  font-family: var(--en-font);
`

const Name = styled.div`
  font-family: var(--en-font);
  font-size: 1.375rem;
  line-height: normal;
  letter-spacing: 0.06em;
  font-weight: 700;
  margin-top: 0.75rem;
  small {
    font-size: 0.75rem;
  }
`

const NameJa = styled.div`
  font-size: 0.875rem;
  line-height: normal;
  font-weight: 700;
  margin-top: 0.5rem;
`

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`

const RadioOuter = styled.div`
  cursor: pointer;
  user-select: none;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  background-color: white;
  transition: border-color 0.4s;
  border: 0.25rem solid transparent;
  position: relative;
  border: 1px solid #E7E7E7;
  transition: border-color 0.4s;
  display: flex;
  &.active {
    border-color: var(--text-color);
  }
  &::before {
    content: '';
    display: block;
    border: 1px solid #E7E7E7;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 10rem;
    background-clip: content-box;
    padding: 0.125rem;
    transition: background-color 0.4s;
    margin-top: 0.125rem;
    margin-right: 0.5rem;
  }
  &.active::before {
    background-color: var(--text-color);
  }
`

const RadioInner = styled.div`
  flex: 1 1 0;
`

const PriceRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 0.25rem;
`

const Label = styled.div`
  font-size: 0.8125rem;
  margin-right: auto;
`

const Price = styled.div`
  font-size: 0.9375rem;
  font-weight: 500;
  small {
    font-size: 0.75rem;
    color: #B3B3B3;
  }
`

const RadioNote = styled.div`
  font-size: 0.625rem;
  line-height: 1.75;
`


const Note = styled.div`
  font-size: 0.5625rem;
  line-height: normal;
  margin-top: 1rem;
  margin-left: -1em;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  cursor: pointer;
  svg {
    display: block;
    height: 1.25rem;
    width: auto;
  }
`

const BuyButton = styled.div`
  background-color: var(--blue);
  color: white;
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 10rem;
  margin-top: 2rem;
  cursor: pointer;
  font-family: var(--sans-serif-ja-font);
`

const NoticeButton = styled.div`
  font-size: 0.8125rem;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 1rem;
`

const Head = styled.div`
  font-weight: 700;
  line-height: 1.25;
  font-size: 1.25rem;
`

const Text = styled.div`
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 2;
  li {
    margin-left: 1em;
    position: relative;
    &::before {
      content: '・';
      position: absolute;
      right: 100%;
      top: 0;
    }
  }
`

const AgreementContainer = styled.label`
  display: block;
  display: flex;
  padding: 1rem 0;
  margin-top: 1rem;
`

const AgreementCheckbox = styled.input``

const AgreementLabel = styled.div`
  font-weight: 700;
`

const AgreementButton = styled.div`
  background-color: #D9D1BE;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: var(--en-font);
  font-weight: 700;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 10rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: opacity 0.4s;
  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }
`

const PopupText = styled.div`
  li {
    margin-left: 1em;
    position: relative;
    &::before {
      content: '・';
      position: absolute;
      right: 100%;
      top: 0;
    }
  }
  a {
    text-decoration: underline;
  }
`

export type Variant = {
  id: string | number,
  label: string,
  price: number,
  netContent: string,
  note?: string,
  subscriptionNotice?: ReactNode
}
type CheckoutPopupData = {
  name: string,
  description: ReactNode,
  variants: Variant[]
  note?: ReactNode,
  notice?: ReactNode,
  agreement?: ReactNode,
}
type CheckoutPopupProps = {
  open: boolean
  onClose: () => void
  onClick: (id: number | string) => void | Promise<void>
  data: CheckoutPopupData
  defaultSelect?: number | string | undefined
}
export const CheckoutPopup: FunctionComponent<CheckoutPopupProps> = ({open, onClose, data, onClick, defaultSelect}) => {

  const [activeId, setActiveId] = useState(defaultSelect ?? data.variants[0]?.id)
  const [sending, setSending] = useState(false)
  const {open: openModal} = useModal()
  useEffect(() => {
    if(isNullish(activeId)){
      setActiveId(data.variants[0]?.id)
    }
  }, [data.variants])
  useEffect(() => {
    setActiveId(defaultSelect ?? defaultSelect ?? data.variants[0]?.id)
  }, [defaultSelect])
  return <Outer className={classNames({hide: !open})}>
    <Bg onClick={onClose}/>
    <Inner>
      <Name dangerouslySetInnerHTML={{__html: data.name}} />
      <RadioContainer>
        {
          data.variants.map(variant => {
            const [label, note] = variant.label.split('/')
            return <Fragment key={variant.id}>
              <RadioOuter className={classNames({active: variant.id === activeId})} onClick={() => setActiveId(variant.id)}>
                <RadioInner>
                  <PriceRow>
                    <Label>{label}</Label>
                    <Price>
                      ¥{variant.price.toLocaleString('en-US')}
                      <small>
                        (税込)/{variant.netContent}
                      </small>
                    </Price>
                  </PriceRow>
                  <RadioNote>
                    {note}
                  </RadioNote>
                  <RadioNote>
                    {variant.note}
                  </RadioNote>
                </RadioInner>
              </RadioOuter>
              {
                variant.subscriptionNotice && 
                <NoticeButton onClick={() => openModal(({resolve}) => <Popup onClose={resolve}><PopupText>{variant.subscriptionNotice}</PopupText></Popup>)}>
                  定期コースについて
                </NoticeButton>
              }
            </Fragment>
          })
        }
      </RadioContainer>
      <BuyButton onClick={async () => {
        setSending(true)
        await onClick(data.variants.find(item => item.id === activeId)!.id)
        setSending(false)
      }}>カートに追加する</BuyButton>
      <CloseButton onClick={onClose}>
        <SvgCross />
      </CloseButton>
    </Inner>
    <Overlay className={classNames({hide: !sending})} />
  </Outer>
}

type AgreementPopupProps = {
  children?: ReactNode
  onClose?: () => void
  onAgree?: () => void
}
const AgreementPopup: FunctionComponent<AgreementPopupProps> = ({children, onClose, onAgree}) => {
  const [checked, setChecked] = useState(false)
  return <Popup onClose={onClose}>
    <Head>ご購入前の同意事項</Head>
    <Text>
      {children}
    </Text>
    <AgreementContainer>
      <AgreementCheckbox type="checkbox" onChange={e => setChecked(e.currentTarget.checked)}/>
      <AgreementLabel>上記を読んだ上で同意する</AgreementLabel>
    </AgreementContainer>
    <AgreementButton className={classNames({disabled: !checked})} onClick={onAgree}>カートに入れる</AgreementButton>
  </Popup>
}